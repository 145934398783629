import React from 'react';
import ContactUsForm from './../form/contact-us-form';

class ContactUs extends React.Component {
  render() {
    return (
      <div id="contact-us" className="contact-us">
        <div className="container">
          <div className="d-flex flex-md-row flex-column-reverse justify-content-between">
            <div className="left align-self-center">
              <h5>Kontak Kami</h5>
              <ContactUsForm></ContactUsForm>
            </div>
            <div className="middle"></div>
            <div className="right align-self-center">
              <div className="img"><img src="img/contact-us-mail.png" className="w-75" alt="" /></div>
              <div className="contact">
                <div className="d-flex flex-column flex-md-row justify-content-center">
                  <div className="left-contact">
                    <div className="item">
                      <div className="label">Email</div>
                      <a href="#">
                        <div className="content">
                          <img src="img/mail-icon.png" alt="" />
                          <span>altea@gmail.com</span>
                        </div>
                      </a>
                    </div>
                    <div className="item">
                      <div className="label">Pusat informasi</div>
                      <a href="#">
                        <div className="content">
                          <img src="img/phone-icon.png" alt="" />
                          <span>021-9820-0932</span>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="middle-contact"></div>
                  <div className="right-contact align-self-center">
                    <a href="#"><img src="img/ig-icon.png" alt="" /></a>
                    <a href="#"><img src="img/fb-icon.png" alt="" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
