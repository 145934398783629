import React from 'react';

class AboutUs extends React.Component {
  render() {
    return (
      <div id="about-us" className="about-us">
        <div className="container">
          <div className="d-flex flex-column flex-md-row">
            <div className="left">
              <img src="img/about-us-img.png" alt="" />
            </div>
            <div className="middle"></div>
            <div className="right align-self-center">
              <h5>Tentang kami</h5>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
