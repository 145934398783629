import React from 'react';

class HowTo extends React.Component {
  render() {
    return (
      <div className="how-to">
        <div className="container">
          <div className="title">Cara Mendapatkan Cashback</div>
          <div className="list">
            <div className="d-flex flex-md-row flex-column">
              <div className="item flex-grow-1">
                <div className="d-flex flex-column h-100">
                  <div className="img">
                    <div className="d-flex flex-column h-100 justify-content-center">
                      <div className="text-center"><img src="img/how-to-1.png" alt="" /></div>
                    </div>
                  </div>
                  <div className="caption flex-grow-1">
                    <h5>1. Foto Kwitansi</h5>
                    <p>Foto kwitansi-mu ketika bertransaksi di rumah sakit</p>
                  </div>
                </div>
              </div>
              <div className="item flex-grow-1">
                <div className="d-flex flex-column h-100">
                  <div className="img">
                    <div className="d-flex flex-column h-100 justify-content-center">
                      <div className="text-center"><img src="img/how-to-2.png" alt="" /></div>
                    </div>
                  </div>
                  <div className="caption flex-grow-1">
                    <h5>2. Masukkan Data Kwitansi</h5>
                    <p>Seperti jumlah transaksi, tanggal transaksi, & cabang rumah sakit</p>
                  </div>
                </div>
              </div>
              <div className="item flex-grow-1">
                <div className="d-flex flex-column h-100">
                  <div className="img">
                    <div className="d-flex flex-column h-100 justify-content-center">
                      <div className="text-center"><img src="img/how-to-3.png" alt="" /></div>
                    </div>
                  </div>
                  <div className="caption flex-grow-1">
                    <h5>3. Unggah Kwitansi</h5>
                    <p>Unggah kwitansi kamu di aplikasi altea loyalty</p>
                  </div>
                </div>
              </div>
              <div className="item flex-grow-1">
                <div className="d-flex flex-column h-100">
                  <div className="img">
                    <div className="d-flex flex-column h-100 justify-content-center">
                      <div className="text-center"><img src="img/how-to-4.png" alt="" /></div>
                    </div>
                  </div>
                  <div className="caption flex-grow-1">
                    <h5>4. Dapat Cashback!</h5>
                    <p>Admin akan segera mengirimkan cashback ke ewallet kamu!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowTo;
