import React from 'react';
import { useFormik } from 'formik';
import validator from 'validator';
import Swal from 'sweetalert2';
import axios from 'axios';
import * as Config from './../../config';

const ContactUsForm = () => {
  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    onSubmit: async values => {
      if(validator.isEmpty(values.name)) return Swal.fire('Failed', 'Name is required', 'warning');
      if(!validator.isEmail(values.email)) return Swal.fire('Failed', 'Email is invalid', 'warning');
      if(validator.isEmpty(values.message)) return Swal.fire('Failed', 'Message is required', 'warning');
      let formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('message', values.message);
      let response = await axios.post(`${Config.API_URL}/send-contact-us-email`, formData);
      try {
        let data = response.data;
        if(data.status) {
          return Swal.fire('Success', data.message, 'success');
        } else {
          return Swal.fire('Success', data.message, 'error');
        }
      } catch(error) {
        return Swal.fire('Error', 'Unable to connect to server', 'error');
      }
    },
  });
  return (
    <form onSubmit={form.handleSubmit}>
      <div className="form-field">
        <label>Nama</label>
        <input type="text" name="name" onChange={form.handleChange} value={form.values.name} placeholder="Masukkan nama kamu" />
      </div>
      <div className="form-field">
        <label>Email</label>
        <input type="email" name="email" onChange={form.handleChange} value={form.values.email} placeholder="Masukkan email kamu" />
      </div>
      <div className="form-field">
        <label>Pesan</label>
        <textarea name="message" onChange={form.handleChange} value={form.message} cols="30" rows="5" placeholder="Masukkan pesan kamu"></textarea>
      </div>
      <div className="form-field">
        <button type="submit" disabled={form.isSubmitting}>{form.isSubmitting ? <span className="spinner-border spinner-border-sm"></span> : ''} <span>Kirim Pesan</span></button>
      </div>
    </form>
  );
}

export default ContactUsForm;
