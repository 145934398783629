import React from 'react';

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }

  render() {
    return (
      <div className="topbar">
        <nav className="navbar navbar-expand-md navbar-light fixed-top">
          <div className="container">
            <a className="navbar-brand" href="/">
              <div className="d-flex flex-md-row flex-column">
                <div><img src="img/logo.png" alt="AlteaCare" /></div>
                <div className="align-self-center"><img src="img/mitra-keluarga-logo.png" className="ml-1" alt="Mitra Keluarga" /></div>
              </div>
            </a>

            <button onClick={this.toggleMenu} className="navbar-toggler" type="button">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={"collapse navbar-collapse " + (this.state.menu ? 'show' : '')}>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#about-us">Tentang Kami</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#download">Download Aplikasi</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact-us">Kontak Kami</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default TopBar;
