import React from 'react';

class Banner extends React.Component {
  render() {
    return (
      <div className="banner">
        <div className="background">
          <div className="container">
            <div className="d-flex flex-column-reverse flex-md-row">
              <div className="left flex-grow-1 align-self-center">
                <h3>Unggah Kwitansi Bisa <br />Dapat Cashback!</h3>
                <p>Altea Loyalty memungkinkan kamu untuk dapat cashback hanya dengan mengunggah kwitansi transaksi rumah sakit melalui HP-mu. Ada juga info seputar berita, promo dan info menarik lainnya !</p>
                <div className="download-btn d-flex flex-column flex-md-row">
                  <div><a href="#"><img src="img/play-download.png" alt="" /></a></div>
                  <div style={{minWidth: '30px'}}></div>
                  <div><a href="#"><img src="img/store-download.png" alt="" /></a></div>
                </div>
              </div>
              <div style={{minWidth: '30px'}}></div>
              <div className="right flex-grow-1">
                <img src="img/banner-right-img.png" alt="" />
              </div>
            </div>
          </div>
          <div className="cover"></div>
        </div>
      </div>
    );
  }
}

export default Banner;
