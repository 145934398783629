import React from 'react';
import Home from './page/home';

class App extends React.Component {
  render() {
    return (
      <Home></Home>
    );
  }
}

export default App;
