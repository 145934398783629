import React from 'react';

class Download extends React.Component {
  render() {
    return (
      <div id="download" className="download">
        <div className="container">
          <div className="title">Download Altea Loyalty di</div>
          <div className="download-btn d-flex flex-column flex-md-row justify-content-center">
            <div><a href="#"><img src="img/play-download.png" alt="" /></a></div>
            <div style={{width: '30px'}}></div>
            <div><a href="#"><img src="img/store-download.png" alt="" /></a></div>
          </div>
          <div className="list">
            <div className="d-flex flex-md-row flex-column">
              <div className="item flex-grow-1">
                <img src="img/download-1.png" alt="" />
              </div>
              <div className="item flex-grow-1">
                <img src="img/download-2.png" alt="" />
              </div>
              <div className="item flex-grow-1">
                <img src="img/download-3.png" alt="" />
              </div>
              <div className="item flex-grow-1">
                <img src="img/download-4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Download;
